<!-- 权限配置 -->
<template>
  <div class="role_content">
    <div class="level1" v-for="item in rolePer" :key="item.index">
      <el-checkbox v-model="item.power" class="span1" @change="changeCheckFun(item)"> {{ item.title }}</el-checkbox>
      <div :class="item.level === true?'levelT':'level2'">
        <div v-for="(item1,in1dex) in item.children" :key="in1dex">
          <el-checkbox v-model="item1.power" class="span2" @change="changeCheckFun(item,item1)"> {{ item1.title }}</el-checkbox>
          <div class="level3">
            <div v-for="(item2,index2) in item1.children" :key="index2">
              <el-checkbox v-model="item2.power" class="span3" :disabled="item2.disabled" @change="changeCheckFun(item,item1,item2)"> {{ item2.title }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_pos">
      <div class="flexCE">
        <div class="settingCancel" @click="cancel">取消</div>
        <div class="settingConfirm" @click="handleConfirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Role',
  props: ['roleEdit'],
  data () {
    return {
      test: true,
      rolePer: []
    }
  },
  watch: {
    roleEdit (val) {
      this.rolePer = this.roleEdit
    }
  },
  created () {
    if (this.roleEdit === undefined) {
      this.rolePer = [
        {
          name: 'equip',
          title: '设备管理',
          ico: 'iconequip',
          level: true,
          power: false,
          disabled: true,
          children: [
            {
              name: 'equipType',
              title: '设备类型',
              power: false,
              children: []
            },
            {
              name: 'equip',
              title: '设备列表',
              power: false,
              disabled: true,
              children: [
                {
                  title: '新增',
                  name: 'add',
                  disabled: true,
                  power: false,
                  children: []
                },
                {
                  title: '删除',
                  name: 'delete',
                  disabled: true,
                  power: false,
                  children: []
                },
                {
                  title: '编辑',
                  name: 'edit',
                  disabled: true,
                  power: false,
                  children: []
                },
                {
                  title: '导出',
                  name: 'import',
                  disabled: false,
                  power: false,
                  children: []
                }
              ]
            }
          ]
        },
        {
          name: 'user',
          title: '用户管理',
          ico: 'iconuser',
          level: true,
          power: false,
          children: [
            {
              name: 'userSetting',
              title: '用户列表',
              power: false,
              children: [
                {
                  title: '新增',
                  name: 'add',
                  power: false,
                  children: []
                },
                {
                  title: '删除',
                  name: 'delete',
                  power: false,
                  children: []
                },
                {
                  title: '编辑',
                  name: 'edit',
                  power: false,
                  children: []
                },
                {
                  title: '重置密码',
                  name: 'pwd',
                  power: false,
                  children: []
                }
              ]
            },
            {
              name: 'systemRole',
              title: '系统角色',
              power: false,
              children: [
                {
                  title: '新增',
                  name: 'add',
                  power: false,
                  children: []
                },
                {
                  title: '删除',
                  name: 'delete',
                  power: false,
                  children: []
                },
                {
                  title: '权限编辑',
                  name: 'edit',
                  power: false,
                  children: []
                }
              ]
            }
          ]
        },
        // {
        //   name: 'build',
        //   title: '建筑管理',
        //   ico: 'iconbuild',
        //   level: false,
        //   power: false,
        //   children: [
        //     {
        //       title: '新增',
        //       name: 'add',
        //       power: false,
        //       children: []
        //     },
        //     {
        //       title: '删除',
        //       name: 'delete',
        //       power: false,
        //       children: []
        //     },
        //     {
        //       title: '编辑',
        //       name: 'edit',
        //       power: false,
        //       children: []
        //     }
        //   ]
        // },
        {
          name: 'fire',
          title: '微消防管理',
          ico: 'iconfire',
          level: false,
          power: false,
          children: [
            {
              title: '新增',
              name: 'add',
              power: false,
              children: []
            },
            {
              title: '删除',
              name: 'delete',
              power: false,
              children: []
            },
            {
              title: '编辑',
              name: 'edit',
              power: false,
              children: []
            }
          ]
        },
        {
          name: 'plan',
          title: '预案管理',
          ico: 'iconplan',
          level: false,
          power: false,
          children: [
            {
              title: '新增',
              name: 'add',
              power: false,
              children: []
            },
            {
              title: '删除',
              name: 'delete',
              power: false,
              children: []
            },
            {
              title: '编辑',
              name: 'edit',
              power: false,
              children: []
            }
          ]
        },
        {
          name: 'onsite',
          title: '巡检管理',
          ico: 'icononsite',
          level: true,
          power: false,
          children: [
            {
              name: 'onsiteLabel',
              title: '巡检点',
              power: false,
              children: [
                {
                  title: '新增',
                  name: 'add',
                  power: false,
                  children: []
                },
                {
                  title: '删除',
                  name: 'delete',
                  power: false,
                  children: []
                },
                {
                  title: '编辑',
                  name: 'edit',
                  power: false,
                  children: []
                },
                {
                  title: '二维码',
                  name: 'qr',
                  power: false,
                  children: []
                }
              ]
            },
            {
              name: 'onsitePlan',
              title: '巡检计划',
              power: false,
              children: [
                {
                  title: '新增',
                  name: 'add',
                  power: false,
                  children: []
                },
                {
                  title: '删除',
                  name: 'delete',
                  power: false,
                  children: []
                },
                {
                  title: '是否启用',
                  name: 'use',
                  power: false,
                  children: []
                }
              ]
            },
            {
              name: 'onsiteTask',
              title: '巡检任务',
              power: false,
              children: [
                {
                  title: '导出',
                  name: 'export',
                  power: false,
                  children: []
                },
                // {
                //   title: '督办',
                //   name: 'super',
                //   power: false,
                //   children: []
                // }
              ]
            },
            // {
            //   name: 'superRecord',
            //   title: '督办记录',
            //   power: false,
            //   children: []
            // }
          ]
        }
        // {
        //   name: 'system',
        //   title: '系统管理',
        //   ico: 'iconsystem',
        //   level: true,
        //   power: false,
        //   children: [
        //     {
        //       name: 'version',
        //       title: '版本管理',
        //       power: false,
        //       children: [
        //         // {
        //         //   title: '新增',
        //         //   name: 'add',
        //         //   power: false,
        //         //   children: []
        //         // },
        //         // {
        //         //   title: '删除',
        //         //   name: 'delete',
        //         //   power: false,
        //         //   children: []
        //         // },
        //         // {
        //         //   title: '编辑',
        //         //   name: 'edit',
        //         //   power: false,
        //         //   children: []
        //         // }
        //       ]
        //     },
        //     {
        //       name: 'log',
        //       title: '日志管理',
        //       power: false,
        //       children: []
        //     }
        //   ]
        // }
      ]
    } else {
      this.rolePer = this.roleEdit
    }
  },
  methods: {
    setHidden (item, booem) {
      if (item.children.length > 0) {
        item.children.forEach(itm => {
          if (item.disabled) {
            if (!itm.disabled) {
              itm.power = booem
            }
          } else {
            itm.power = booem
            if (itm.children.length > 0) {
              itm.children.forEach(itm2 => {
                itm2.power = booem
              })
            }
          }
        })
      }
    },
    changeCheckFun (item, item1, item2) {
      if (item && item1 === undefined && item2 === undefined) {
        if (!item.power) {
          this.setHidden(item, false)
        } else {
          this.setHidden(item, true)
        }
      }
      if (item1 && item2 === undefined) {
        if (item1.power) {
          item.power = true
          this.setHidden(item1, true)
        } else {
          this.setHidden(item1, false)
        }
      }
      if (item2) {
        if (item2.power) {
          item.power = true
          item1.power = true
          this.setHidden(item2, true)
        } else {
          this.setHidden(item2, false)
        }
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    handleConfirm () {
      if (this.Test(this.rolePer)) {
        this.$emit('FromConfirm', this.rolePer)
      } else {
        this.$emit('FromConfirm', null)
      }
    },
    Test (arr) {
      return arr.some((item, index) => {
        return item.power
      })
    }
  }
}
</script>

<style scoped>
.role_content{
  width: 100%;
  height: 500px;
  position: relative;
  overflow-y: scroll;
  padding-bottom: 20px;
  scrollbar-width: none;/* //火狐兼容 */
}
.role_content::-webkit-scrollbar {
  width: 1px;
}
.btn_pos{
  position: fixed;
  bottom: 30px;
  right: 30px;
}
.level2,.level3{
  padding-left: 25px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.levelT{
  padding-left: 25px;
  box-sizing: border-box;
}
.span1,.span2,.span3{
  margin-right: 15px;
}
.span1 >>> .el-checkbox__label,
.span2 >>> .el-checkbox__label,
.span3 >>> .el-checkbox__label{
  padding-left: 5px;
}
</style>
