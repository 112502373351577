<template>
  <div class="setting_main_bg">
    <div class="flexAC" v-if="roleBtnArray[0].power">
        <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
    </div>
    <div class="flexAC" :class="roleBtnArray[0].power === true?'search_top':''">
      <el-input v-model="cont"  placeholder="请输入搜索内容" class="contInput"></el-input>
      <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
      <el-button class="settingReset" @click="handleReset">重置</el-button>
    </div>
    <div class="role_table">
      <el-table
        stripe
        :data="roleList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="角色名称"
          prop="rolename">
        </el-table-column>
        <el-table-column
          label="角色职务"
          prop="rolemark">
        </el-table-column>
        <el-table-column
          label="单位名称"
          prop="companyname">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope" v-if="scope.row.companyid != ''">
            <el-button type="text" size="small" @click="handleRoleEdit(scope.row)" v-if="roleBtn[2].power">权限编辑</el-button>
            <el-button type="text" size="small" style="color:red;" @click="handleDelete(scope.row)" v-if="roleBtn[1].power">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 新增角色  -->
    <el-drawer
      title="角色新增"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :visible.sync="addFromShow"
      :wrapperClosable="false"
      :close-on-click-modal="false"
      :before-close="cancelAdd"
      class="roleAddDialog">
      <div class="gyDialog drawer_content">
        <el-form :model="addfrom" ref="addFrom" :rules="formRules" >
          <el-form-item label="角色名称" class="foItem"  prop="rolename">
            <el-input v-model="addfrom.rolename"  placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色职务" class="foItem"  prop="rolename">
            <el-input v-model="addfrom.rolemark"  placeholder="请输入角色职务"></el-input>
          </el-form-item>
          <el-form-item label="角色权限" class="foItem" prop="roleper">
            <div class="roleBox">
              <Role @cancel="cancelAdd" @FromConfirm="addFromConfirm"></Role>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <!-- 编辑  -->
    <el-drawer
      title="角色编辑"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :visible.sync="editFromShow"
      :wrapperClosable="false"
      :close-on-click-modal="false"
      :before-close="cancelEdit"
      class="roleAddDialog">
      <div class="gyDialog drawer_content">
        <el-form :model="editfrom" ref="editFrom" :rules="editformRules" >
          <el-form-item label="角色名称" class="foItem"  prop="rolename">
            <el-input v-model="editfrom.rolename"  placeholder="请输入角色名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="角色职务" class="foItem"  prop="rolename">
            <el-input v-model="editfrom.rolemark"  placeholder="请输入角色职务"></el-input>
          </el-form-item>
          <el-form-item label="角色权限" class="foItem" prop="roleper">
            <div class="roleBox">
              <Role @cancel="cancelEdit" @FromConfirm="editFromConfirm" :roleEdit="roleEdit"></Role>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Role from '@/components/setting/role.vue'
import { roleQuery, roleDelete, roleAdd, roleEdit } from '@/http/api/user'
var Base64 = require('js-base64').Base64
export default {
  name: 'systemRole',
  props: ['roleBtn'],
  components: {
    Role
  },
  data () {
    const roleSelete = (rule, value, callback) => {
      if (this.isSelect === false) {
        return callback(new Error('请配置权限'))
      }
      return callback()
    }
    return {
      cont: '',
      time: [],
      page: 1,
      size: 10,
      total: 0,
      roleList: [],
      companyid: '',
      addFromShow: false,
      addfrom: {
        rolename: '',
        rolemark: '',
        roleper: '',
        companyid: ''
      },
      formRules: {
        rolename: [
          { required: true, trigger: 'blur', message: '角色名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        rolemark: [
          { required: true, trigger: 'blur', message: '角色职务不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        roleper: [
          { required: true, validator: roleSelete, trigger: 'blur' }
        ]
      },
      isSelect: false,
      editFromShow: false,
      editfrom: {},
      editformRules: {
        rolename: [
          { required: true, trigger: 'blur', message: '角色名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        rolemark: [
          { required: true, trigger: 'blur', message: '角色职务不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        roleper: [
          { required: true, validator: roleSelete, trigger: 'blur' }
        ]
      },
      roleEdit: null,
      roleBtnArray: []
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
      // console.log(this.roleBtnArray)
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      const parmas = {
        rolename: this.cont,
        roleid: '',
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size
      }
      roleQuery(parmas).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.roleList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.roleList = []
            this.total = 0
          }
        } else {
          this.roleList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.cont = ''
      this.init()
    },
    handleAdd () {
      this.addFromShow = true
    },
    cancelAdd () {
      this.addFromShow = false
      this.$refs.addFrom.resetFields()
    },
    // 添加提交
    addFromConfirm (res) {
      if (res === null) {
        this.isSelect = false
      } else {
        this.isSelect = true
      }
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        var str = JSON.stringify(res)
        var role = Base64.encode(str)
        const params = {
          rolename: this.addfrom.rolename,
          rolemark: this.addfrom.rolemark,
          roleper: role,
          companyid: this.companyid
        }
        roleAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.cancelAdd()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 展示权限编辑
    handleRoleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
      var role = JSON.parse(Base64.decode(row.roleper))
      this.roleEdit = role
    },
    // 关闭权限编辑
    cancelEdit () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 编辑提交
    editFromConfirm (res) {
      if (res === null) {
        this.isSelect = false
      } else {
        this.isSelect = true
      }
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        var str = JSON.stringify(res)
        var role = Base64.encode(str)
        const params = {
          rolemark: this.editfrom.rolemark,
          roleper: role,
          roleid: this.editfrom.roleid
        }
        roleEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.cancelEdit()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        roleDelete({ roleid: row.roleid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.search_top,.role_table{
  margin-top: 20px;
}
.roleAddDialog >>> .el-drawer__header{
  padding: 15px 10px;
  border-bottom: 1px solid #F0F3F8;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}
.drawer_content{
  padding: 0px 50px 30px;
  box-sizing: border-box;
}
.roleBox{
  width: 100%;
  position: absolute;
  top: 40px;
}
</style>
